<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <NuxtPwaManifest />
</template>

<script setup lang="ts">
import { useRoute, useT3Api } from '#imports'

// Hack for loading pageData for layout components
const { getPage, pageData } = useT3Api()
const { fullPath } = useRoute()

if (import.meta.server) {
  try {
    const initialRenderPageData = await getPage(fullPath)
    pageData.value = initialRenderPageData
  } catch (error) {
    console.error('Error fetching page data for initial render: ', error)
  }
}
// END
</script>
