import type { FormKitValidationRule } from '@formkit/validation'

const fileSize: FormKitValidationRule = ({ value }, maxSize) => {
  if (!Array.isArray(value) || !maxSize) return true

  const fileList = value.map(({ file }) => file)
  if (!fileList.length) return true

  const maxFileSize = isNaN(parseFloat(maxSize)) ? false : parseFloat(maxSize)
  if (!maxFileSize) return false

  const totalSize = fileList.reduce((totalSize, file) => totalSize + file.size, 0) / 1000 / 1000

  return totalSize <= maxFileSize
}

export default fileSize
