<template>
  <ErrorPage
    :error="error"
    :translation="translation[currentLocale as keyof typeof translation]"
    :is-debug="Boolean(debug)"
    :is-main-page="isMainPage"
  />
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import type { NuxtError } from '#app'
import { useCurrentLocale, useRoute, useT3Options } from '#imports'

const ErrorPage = defineAsyncComponent(
  () => import('~ui/components/Base/ErrorPage/ErrorPage.vue')
)

defineProps<{ error: NuxtError }>()

const currentLocale = useCurrentLocale()
const { options } = useT3Options()

const { debug } = useRoute().query
const isMainPage = computed(() => !options.api.baseUrl?.includes('t3headless'))

const translation = {
  en: {
    error: 'Error',
    header: 'Unfortunately, the page you\'re looking for could not be found.',
    text: 'Check out our {{link}}.',
    linkArray: [
      {
        label: 'home page',
        href: '/'
      }
    ]
  },
  pl: {
    error: 'Błąd',
    header: 'Przepraszamy, nie udało nam się znaleźć szukanej strony.',
    text: 'Zapraszamy do odwiedzenia naszej {{link}}.',
    linkArray: [
      {
        label: 'strony głównej',
        href: '/pl'
      }
    ]
  },
  de: {
    error: 'Error',
    header: 'Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden.',
    text: 'Besuchen Sie unsere {{link}}.',
    linkArray: [
      {
        label: 'Homepage',
        href: '/de'
      }
    ]
  }
}
</script>
