import { default as _91_46_46_46slug_93TgMculuADfMeta } from "/var/builds/macopediapwafront/production/releases/139429/macopedia-shared/pages/[...slug].vue?macro=true";
import { default as T3PagePb6QCS3VdPMeta } from "/var/builds/macopediapwafront/production/releases/139429/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.8/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/var/builds/macopediapwafront/production/releases/139429/macopedia-shared/pages/[...slug].vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/var/builds/macopediapwafront/production/releases/139429/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.8/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]